input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important; }

.ant-form,
.ant-form-item-label > label {
  color: $c-main;
  opacity: 1; }


.ant-input,
.ant-input-affix-wrapper {
  border-radius: 4px; }


.form-fixed-buttons {
  height: 70px;
  > div {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background: #ffffff;
    padding: 20px;
    z-index: 100;
    box-shadow: 0px 3px 15px #0000001A; } }
