@import '../../assets/stylesheets/_helpers/_index_helpers';

.oCard {
  @include baseCard;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  header {
    display: flex;
    align-items: center; }
  h1 {
    margin: 0;
    color: #000;
    font-size: 18px;
    font-weight: 700; }
  h2 {
    margin: -3px 0 5px;
    font-size: 15px;
    font-weight: 500; }
  ul {
    list-style: none;
    padding: 0;
    margin-bottom: 0; }
  footer {
    margin-top: 15px;
    h3 {
      margin: 0;
      color: inherit;
      font-size: 15px;
      font-weight: 700;
      + p {
        margin-top: -3px;
        font-size: 13px; } } } }



.oCard__image {
  @include avatar(56px, 56px); }

.oCard__unit {
  position: relative;
  display: flex;
  background: $c-green;
  border-radius: 8px;
  padding: 0;
  margin-right: 10px;
  overflow: hidden;
  color: #fff;
  font-size: 12px;
  span {
    position: relative;
    display: inline-block;
    padding: 1px 10px 2px;
    z-index: 2;
    font-size: 12px; }
  .oCard__unit__rest {
      background: $c-grey-l;
      color: $c-main; }
  .oCard__unit__open {
    background: $c-orange; }
  .oCard__unit__accepted {
    background: $c-green; }
  .oCard__unit__rejected {
    background: $c-red; }

  div {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    background: $c-grey-l; }
  p {
    margin: 0; } }
footer,
.l-nav__user {
  .oCard__unit {
    margin: 0;
    padding: 7px 0 0;
    div {
      right: auto;
      left: 0;
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px; } } }
.oCard__tags {
  display: flex;
  padding-left: 10px;
  .cat-tag {
    margin-bottom: 5px; }
  ul {
    display: flex;
    margin-right: 5px; } }
[class^="oCard__tag--"] {
  @include baseTag;
  border: 1px solid #000; }
.oCard__tag--green {
  border-color: $c-green;
  color: $c-green; }
.oCard__tag--orange {
  border-color: $c-orange;
  color: $c-orange; }
.oCard__tag--bluelight {
  border-color: #5DD0FA;
  color: #5DD0FA; }
.oCard__tag--purple {
  border-color: #FF80BA;
  color: #FF80BA; }

.oCard__main {
  flex-wrap: nowrap;
  h2 {
    font-size: 17px;
    font-weight: 600; } }

.oCard__info {
  font-size: 12px;
  min-width: 100px;
  img {
    margin-right: 10px; }
  li {
    display: flex;
    align-items: flex-start;
    margin-bottom: 10px;
    &:last-child {
      margin: 0; } } }

.oCard__badges {
  margin-top: -15px;
  transform: translateY(-50%);
  display: flex;
  li {
    margin-right: 5px;
    &:last-child {
      margin: 0; } } }

.oCard__status {
  display: flex;
  align-items: center;
  margin: 10px 0;
  padding: 10px;
  background: #F8F8F8;
  border-radius: 5px;
  color: $c-text-l; }
.oCard__status--on {
  color: $c-orange;
  background: #FFF5EC;
  span {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 25px;
    height: 25px;
    margin-right: 10px;
    padding: 3px;
    border-radius: 50%;
    background: $c-orange;
    color: #fff; } }


.oCard__btn {}
.oCard__btn--orange {
  background: $c-orange;
  .ant-btn {}
  background: $c-orange !important; }

.oCard__btn--yellow {
  color: $c-main;
  background: $c-yellow !important;
  .ant-btn {}
  color: $c-main !important; }


.oCard__orderStatus--accepted {
  color: $c-green; }
.oCard__orderStatus--rejected {
  color: $c-red; }
.oCard__orderStatus--shared {
  color: $c-main; }
.oCard__orderStatus--booked {
  color: $c-orange; }

.oCard__credits {
  img {
    margin-right: 10px; }
  p {
    margin-top: 8px; } }
