@import '../../assets/stylesheets/_helpers/_index_helpers';

.subCard {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100% - 30px);
  margin-bottom: 30px;
  border-radius: 16px;
  overflow: hidden;
  box-shadow: 0px 3px 13px #0000001A;
  header {
    min-height: 90px;
    display: flex;
    align-items: flex-end;
    padding: 15px 20px;
    h1 {
      width: 100%;
      font-weight: 600;
      font-size: 22px;
      margin: 0;
      color: #fff;
      > div:first-child {
        margin-bottom: 5px; }
      em {
        font-size: 36px;
        line-height: 40px;
        font-style: normal; }
      span {
        font-size: 18px;
        font-weight: 500; }
      .strike {
        margin-left: 15px;
        font-weight: 400; } } }
  footer {
    padding: 0 20px;
    .ant-btn {
      width: 100%; }
    h2 {
      margin-top: 10px;
      text-align: center;
      font-size: 17px; }
    p {
      color: $c-text-l;
      font-size: 12px; } } }

.subCard__main {
  ul {
    list-style: none;
    padding: 0;
    margin: 0; }
  li {
    display: flex;
    margin-bottom: 15px;
    text-align: left;
    div {
      width: calc(100% -18px); } }
  svg {
    min-width: 18px;
    margin-right: 10px; } }

.subCard__main {
  min-height: 100px;
  padding: 20px; }

.subCard--green {
  header {
    background: $c-green; }
  rect {
    fill: $c-green; }
  strong {
    color: $c-green; } }

.subCard--red {
  header {
    background: $c-red; }
  rect {
    fill: $c-red; }
  strong {
    color: $c-red; }
  .ant-btn {
    background: $c-red; }
  .subCard__info {
    opacity: 0;
    pointer-events: none; } }

.subCard--blue {
  header {
    background: $c-blue; }
  rect {
    fill: $c-blue; }
  strong {
    color: $c-blue; }
  .ant-btn {
    background: $c-blue; }
  .subCard__info {
    h2 {
      color: $c-blue; } } }

.subCard--yellow-d {
  header {
    background: $c-yellow-d; }
  rect {
    fill: $c-yellow-d; }
  strong {
    color: $c-yellow-d; }
  .ant-btn {
    background: $c-yellow-d; }
  .subCard__info {
    h2 {
      color: $c-yellow-d; } } }


.ant-modal-wrap {
  .subCard {
    footer {
      display: none; } } }
