@import '../../assets/stylesheets/_helpers/_index_helpers';

.subscriptions__radio {
  label {
    font-size: 17px;
    font-weight: 600; }
  .badge {
    font-size: 15px; } }

.subscriptions__month {
  .ant-radio-checked {
    .ant-radio-inner {
      border-color: $c-main;
      &::after {
        background: $c-main; } }
    &::after {
      border-color: $c-main; } } }

.subscriptions__year {
  color: $c-green;
  .ant-radio-checked {
    .ant-radio-inner {
      border-color: $c-green;
      &::after {
        background: $c-green; } }
    &::after {
      border-color: $c-green; } } }
.ant-radio-wrapper-checked.subscriptions__year {}


