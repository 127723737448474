@media print {
  .logo {
    margin: 0 auto; }
  .break {
    page-break-after: always;
    clear: both; }
  .highcharts-container {
    width: 100% !important; }

  .pies, .colChart {
    width: 16cm; }
  .ant-row,
  .ant-col {
    display: block !important;
    width: 100% !important; }
  .statisticsCards {
    .sCard {
      margin: 30px;
      display: inline-block;
      width: 40%;
      box-shadow: none; } }
  .statisticsReport-colors {
    * {
      color-adjust: exact;
      -webkit-print-color-adjust: exact;
      print-color-adjust: exact; } } }
