.with-tooltip {
  display: flex;
  align-items: center; }
.tooltip__info {
  margin-left: 10px; }
.abs-centered {
  @extend %abs-centered; }

.flex1 {
  flex: 1; }
.d-flex {
  display: flex; }

.flex-centered {
  @extend %flex-centered; }

.flex-j-sb {
  display: flex;
  justify-content: space-between; }

.align-items-center {
  align-items: center; }

.fullwidth {
  width: 100%; }
.fullheight {
  height: 100%; }
.cursor-pointer {
  cursor: pointer; }

.bg-red {
  background: $c-red !important; }
.bg-green {
  background: $c-green !important; }
.bg-blue {
  background: $c-blue !important; }
.bg-yellow-d {
  background: $c-yellow-d !important; }


$gutterList: -3 0 5 10 15 20 30 40 50;
@each $marginNum in $gutterList {
  .m-#{$marginNum} {
    margin: #{$marginNum}px !important; }
  .mt-#{$marginNum} {
    margin-top: #{$marginNum}px !important; }
  .mb-#{$marginNum} {
    margin-bottom: #{$marginNum}px !important; }
  .ml-#{$marginNum} {
    margin-left: #{$marginNum}px !important; }
  .mr-#{$marginNum} {
    margin-right: #{$marginNum}px !important; }
  .mx-#{$marginNum} {
    margin-right: #{$marginNum}px !important;
    margin-left: #{$marginNum}px !important; }
  .my-#{$marginNum} {
    margin-top: #{$marginNum}px !important;
    margin-bottom: #{$marginNum}px !important; } }

@each $paddingNum in $gutterList {
  .p-#{$paddingNum} {
    padding: #{$paddingNum}px !important; }
  .pt-#{$paddingNum} {
    padding-top: #{$paddingNum}px !important; }
  .pb-#{$paddingNum} {
    padding-bottom: #{$paddingNum}px !important; }
  .pl-#{$paddingNum} {
    padding-left: #{$paddingNum}px !important; }
  .pr-#{$paddingNum} {
    padding-right: #{$paddingNum}px !important; }
  .px-#{$paddingNum} {
    padding-right: #{$paddingNum}px !important;
    padding-left: #{$paddingNum}px !important; }
  .py-#{$paddingNum} {
    padding-top: #{$paddingNum}px !important;
    padding-bottom: #{$paddingNum}px !important; } }
