@import '../../assets/stylesheets/_helpers/_index_helpers';


.badge {
  display: flex;
  align-items: center;
  height: 22px;
  padding: 0 10px;
  border-radius: 10px;
  font-size: 12px;
  line-height: 22px;
  background: $c-grey-l;
  img {
    margin-right: 5px; } }


.badge--red {
  background: #FDE7EC;
  color: $c-red; }
.badge--green {
  background: #EBF6ED;
  color: $c-green; }

[class*='badge--plan'] {
  color: #fff;
  svg {
    width: 14px;
    margin-right: 10px; } }
