@import '../../assets/stylesheets/_helpers/_index_helpers';

.l-header--main {
  background: $c-main;
  height: 65px;
  color: #fff;
  a {
    color: #fff; } }
.l-nav {
  ul {
    list-style: none;
    padding: 0;
    margin: 0; } }
.l-nav__first {
  > li {
    > a,
    > button {
      text-transform: uppercase; } } }
.l-nav__ico {
  margin-right: 10px; }
.l-nav__second {
  display: none; }

.l-nav__user ul li img {
  fill: #fff; }
.l-nav__user-name {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  height: 100%;
  padding-right: 45px;
  i {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    width: 35px;
    height: 35px;
    border-radius: 50%;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover; } }
  div {
    margin-top: -3px;
    color: $c-yellow;
    font-size: 12px; } }

.l-nav__plan {
  a {
    background: $c-green;
    padding: 5px 10px;
    color: #fff; } }

.burger {
  margin: 0 10px; }

@media (max-width: $bp-m - 1) {
  .l-nav__plan {
    margin: 0 15px; }
  .nav-mobile-active {
    .l-nav {
      left: 0;
      opacity: 1; } }
  .l-nav {
    position: fixed;
    top: 70px;
    left: -300px;
    bottom: 0;
    z-index: 1000;
    width: 280px;
    overflow: auto;
    background: $c-main;
    box-shadow: 5px 5px 15px #999;
    opacity: 0;
    transition: left 0.3s, opacity 0.3s;
    transition-timing-function: ease-out;
    ul {
      ul {
        a {
          padding-left: 40px; } } }
    a, span {
      display: block;
      border-bottom: 1px solid $c-text-l;
      padding: 10px 20px; }
    a {
      margin: 0 -15px; }
    button {
      padding: 0; } }


  .l-nav__first {
    > li {
      &.is-active,
      &.has-active-child {
        ul {
          display: block; } } } }

  .l-nav__user {
    img {
      display: none; } }
  .l-nav__user-name {
      display: none; }
  .l-header--main {
    position: fixed;
    z-index: 1000;
    width: 100%;
    > .l-nav__user-name {
      display: flex;
      margin-right: 15px; } } }



$navMainHeight: 77px;
$navSecondHeight: 60px;
@media (min-width: $bp-m) {
  .burger {
    display: none; }
  .l-header {
    position: relative;
    z-index: 10;
    &:after {
      content: "";
      display: block;
      height: $navSecondHeight;
      width: 100%;
      background: $c-main-l; } }
  .l-header--main {
    display: flex;
    align-items: center;
    height: $navMainHeight;
    padding: 0 30px;
    > .l-nav__user-name {
      display: none; } }

  .l-nav {
    display: flex;
    justify-content: space-between;
    flex: 1;
    a {
      height: 100%; } }
  .l-nav__main {
    margin-left: 40px;
    li {
      display: flex;
      align-items: center;
      white-space: nowrap;
      > a,
      > button {
        display: flex;
        align-items: center;
        opacity: 0.5; }
      &.is-active,
      &:hover {
        > a,
        > button {
          opacity: 1; } } } }
  .l-nav__first {
    position: relative;
    display: flex;
    > li {
      height: $navMainHeight;
      padding-right: 50px;
      &.is-active,
      &.has-active-child {
        a {
          opacity: 1; }
        ul {
          display: flex; } }
      &:last-child {
        padding-right: 0; } }

    // riattivare se serve nav con secondo livello visibile all'hover
    // &:hover
    //   > li
    //     &.is-active,
    //     &.has-active-child
    //       ul
    //         display: none
    //     &:hover
    //       ul
 }    //         display: flex

  .l-nav__second {
    position: absolute;
    li {
      height: $navSecondHeight;
      a {
        padding: 0 20px; }
      &:hover,
      &.is-active {
        a {
          background: #747D84; } } } }

  .l-nav__second {
    top: 100%;
    left: -30px; }


  .l-nav__user-name {
    cursor: pointer; }

  .hasNavUserVisible {
    .l-nav__user {
      nav {
        display: block; } } }
  .l-nav__user {
    position: relative;
    nav {
      display: none;
      position: absolute;
      top: 100%;
      right: 0;
      width: 230px;
      background: #fff;
      box-shadow: 0px 3px 13px #00000029;
      &:after {
        position: absolute;
        bottom: 100%;
        right: 7px;
        transform: translateX(-50%);
        content: "";
        display: block;
        width: 0;
        height: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-bottom: 5px solid #fff; }
      a {
        color: $c-main; }
      img {
        fill: #000; } }

    ul {
      display: flex;
      flex-direction: column;
      top: $navMainHeight;
      right: 0;
      padding: 5px 10px;
      color: $c-main;
      li {
        margin-bottom: 5px;
        &:last-child {
          border-top: 1px solid $c-grey-l; }
        a, button {
          display: flex;
          width: 100%;
          align-items: center;
          padding: 5px;
          font-size: 12px;
          color: $c-main; }
        img {
          margin-right: 5px; } } }
    .l-nav__plan {
      a {
        justify-content: center;
        border-radius: 5px;
        color: #fff; } } } }
