@import '../../assets/stylesheets/_helpers/_index_helpers';

.bRed {
  border-color: #FF0000; }

.qrCode2FA {
  border: 2px solid #667079;
  border-radius: 15px; }

.profile__head {
  display: flex;
  align-items: center;
  margin: 30px 0;
  .h1 {
    margin-bottom: 5px; }
  p {
    margin: 0;
    color: $c-text-l; }
  .profile__avatar {
    position: relative;
    width: 85px;
    height: auto;
    margin-right: 15px;
    cursor: pointer;
    > div {
      width: 85px;
      height: 85px;
      display: flex;
      align-items: center;
      border-radius: 50%;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover; } } } }
.profile__ico-edit {
  width: 20px;
  height: auto;
  position: absolute;
  top: 5px;
  right: 8px; }

.profile__sub {
  position: relative;
  .profile_delete {
    position: absolute;
    top: 10px;
    left: 25px; } }

.profile__field-hidden {
  height: 1px;
  overflow: hidden;
  margin: 0; }

.profile__admin-switch {
  width: 50%;
  flex-direction: row !important;
  align-items: center;
  justify-content: flex-end;
  > * {
    flex: none;
    padding: 0 10px !important; } }

.adduser__status {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  .ant-form-item {
    flex-direction: row;
    align-items: center;
    .ant-col {
      flex: auto;
      padding: 0; } }
  button {
    margin-left: 10px; } }
.adduser__btn {
  .ant-form-item-control-input-content {
    display: flex;
    justify-content: flex-end; } }
