.loading {
  &.fixed {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    text-align: center;
    background-color: rgba(255, 255, 255, 0.7);
    z-index: 100;
    &.cover {
      z-index: 110;
      background-color: rgb(250, 250, 250);
    }

    // backdrop-filter: blur(3px);
    svg {
      margin-left: auto;
      margin-right: auto;
      margin-top: calc(50vh - 40px);
    }
  }
  svg {
    fill: var(--primary-color);

    path {
      animation-name: spin-animation;
      animation-duration: 600ms;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
      transform-origin: center;
    }
  }
}
@keyframes spin-animation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
