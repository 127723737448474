@import '../../../assets/stylesheets/_helpers/_index_helpers';

.layout-widget {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100%;
  background: $c-bg-white;
  padding: 30px;

  .box--white {
    width: 100%; } }

@media (max-width: $bp-sm - 1) {
  .abs-centered {
    width: calc(100% - 40px); } }
@media (min-width: $bp-sm) {
  .layout-widget {
    .box--white {
      width: 570px; } } }



@import url('https://fonts.googleapis.com/css2?family=Heebo:wght@300;400;500;600;700;800;900&family=Plus+Jakarta+Sans:wght@400;700&family=Work+Sans:wght@400;500;600&display=swap');

body {
  font-family: 'Heebo', sans-serif; }

a {
  &.active, &:focus {
    outline: none; } }

button {
  &:focus, &.active {
    outline: none; } }

a {
  text-decoration: none;

  &:focus, &:hover, &:active {
    outline: 0;
    box-shadow: none; }

  &:focus, &:hover {
    text-decoration: none; } }

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  padding: 0;
  font-family: 'Heebo', sans-serif; }

a {
  text-decoration: none;
  transition: all 0.5s ease-in-out;

  &:hover {
    text-decoration: none; } }

ul, ol {
  margin: 0;
  padding: 0;
  list-style: none; }

ul li {
  list-style: none; }

p {
  margin: 0;
  padding: 0; }

input:focus, select:focus, textarea:focus {
  outline: none; }

img {
  border-style: none;
  display: inline-block;
  max-width: 100%;
  height: auto; }

.wrapper {
  overflow: hidden; }

/* ====== section-css ===== */

.heart-card {
  background-color: #f33164;
  padding: 20px;
  display: flex;
  border-radius: 30px;
  align-items: center;

  img {
    width: 80px;
    height: 76px;
    margin-right: 20px; }

  p {
    color: #fff;
    font-size: 16px;
    margin-bottom: 10px;
    font-weight: normal;
    text-transform: uppercase; }

  h2 {
    span {
      font-size: 20px;
      font-weight: 700; }

    color: #fff;
    font-size: 48px;
    line-height: 1;
    font-weight: 300;
    margin: 6px 0 3px; } }

.box {
  border: 4px solid #f7b033;
  border-radius: 30px;
  padding: 25px;
  text-align: center;

  h6 {
    color: #f7b033;
    text-align: center;
    font-size: 18px;
    text-transform: uppercase;
    font-weight: 600;
    background: #fff;
    max-width: 169px;
    margin: -36px auto 0; }

  .inner-box {
    background-color: #f7b033;
    border-radius: 10px;
    margin-top: 45px;
    padding: 1px 10px 15px;

    span {
      font-size: 14px;
      color: #fff; }

    h5 {
      font-size: 26px;
      color: #fff;
      font-weight: 400; } }

  .img-holder {
    img {
      height: 100%; }

    text-align: center;
    width: 80px;
    height: 80px;
    background: #fff;
    padding: 18px;
    border-radius: 50%;
    z-index: 9999;
    position: relative;
    margin: -42px auto 5px; }

  &.box-2 {
    border: 4px solid #00a772;

    h6 {
      color: #00a772;
      max-width: 190px; } } }

.inner-boxes {
  display: flex;
  align-items: center;

  .inner-box {
    &:last-child {
      margin-right: 0px; }

    &.leaf-box {
      background-color: #00a772; }

    &.aqua-box {
      background-color: #00b5eb; }

    &.map-box {
      background-color: #a4721e; }

    margin-right: 10px;
    width: 100%; } }

.arrow-img img {
  width: 585px;
  margin-right: 119px;
  height: 100%;
  object-fit: contain;
  margin-bottom: 17px; }

.para p {
  color: #a7a7a7;
  margin-top: 20px;

  span {
    font-weight: 700; } }

.date-text {
  font-size: 10px;
  color: #a7a7a7;

  a {
    color: #a7a7a7;
    text-decoration: underline; } }

@media (min-width: 1440px) {
  .container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
    max-width: 1352px; } }

@media (max-width: 1400px) {
  /* ====== section-css ===== */

  .box {
    padding: 25px 18px; }

  .arrow-img img {
    width: 485px; }

  /* ====== section-css ===== */ }

@media (max-width: 1300px) {
  /* ====== section-css ===== */

  .box {
    padding: 25px 13px; }

  /* ====== section-css ===== */ }

@media (max-width: 1199px) {
  /* ===== section-class ===== */

  .heart-card {
    p {
      font-size: 12px; }

    img {
      width: 60px;
      height: 58px;
      margin-right: 19px; }

    h2 {
      font-size: 36px; } }

  .arrow-img img {
    width: 527px; }

  .heart-card {
    padding: 16px; }

  /* ===== section-class ===== */ }

@media (max-width: 991px) {
  /* ===== section-class ===== */

  .box.box-2 h6 {
    margin: -25px auto 0; }

  .arrow-img img {
    width: 386px; }

  .box {
    &.box-2 {
      padding: 13px 13px; }

    .inner-box h5 {
      font-size: 22px; } }

  .bottom-space {
    margin-bottom: 10px; }

  .date-text {
    text-align: center; }

  .box.box-2 .img-holder {
    width: 72px;
    height: 72px; }

  /* ===== section-class ===== */ }

@media (max-width: 767px) {
  .box {
    h6 {
      font-size: 14px;
      margin: -23px auto 0; }

    border-radius: 16px;
    padding: 13px 13px;

    .inner-box h5 {
      font-size: 16px; }

    .img-holder {
      width: 72px;
      height: 72px; } }

  .arrow-img img {
    width: 286px; }

  .inner-boxes .inner-box {
    margin-right: 6px; }

  .box .inner-box span {
    font-size: 12px;
    display: block;
    margin-bottom: 8px;
    line-height: 16px; } }

@media (max-width: 575px) {
  .inner-boxes {
    display: block; }

  .box {
    margin-top: 30px; }

  .arrow-img {
    display: none; }

  .box {
    h6 {
      margin: -34px auto 0; }

    padding: 25px 16px 20px;

    .img-holder {
      width: 60px;
      height: 60px;
      padding: 13px; }

    &.box-2 h6 {
      max-width: 173px; } }

  /* ===== section-class ===== */

  .para p {
    font-size: 10px; }

  .box h6 {
    font-size: 16px; }

  .date-text {
    text-align: center; }

  /* ===== section-class ===== */ }
