%abs-centered {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%); }

%flex-centered {
  display: flex;
  justify-content: center;
  align-items: center; }
