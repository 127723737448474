@import '../../assets/stylesheets/_helpers/_index_helpers';

.category {
  .anticon-ellipsis {
    transform: rotate(90deg);
    font-weight: bold;
    font-size: 20px; }
  .organizations__btns > div {
    margin-right: 80px; } }


.subcategory {
  border-bottom: 1px solid #E5E5E5;
  padding: 15px;
  &:first-child {
    border-top: 1px solid #E5E5E5; } }

.subcategory__specs {
  @include ulReset;
  display: flex;
  margin-right: 15px;
  li {
    margin-right: 10px;
    padding-right: 10px;
    border-right: 1px solid #9A9A9A;
    line-height: 14px;
    font-size: 15px;
    &:last-child {
      border: 0; } } }


.cat-tag {
  @include baseTag;
  .anticon svg {
    fill: #000; } }
