.env-ribbon {
  position: fixed;
  bottom: 30px;
  right: -200px;
  width: 500px;
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: center;
  transform: rotate(-45deg);
  background-color: #fff;
  box-shadow: 0 2px 15px -3px rgba(0, 0, 0, 0.3);
  opacity: 0.8;
  z-index: 5000;
  pointer-events: none;
  cursor: default;
}

.env-ribbon.dev,
.env-ribbon.development {
  background-color: rgb(252, 188, 49);
  color: #333;
}

.env-ribbon.test {
  background-color: #39d;
  color: #fff;
}

.env-ribbon.prod,
.env-ribbon.production {
  display: none;
}