@import '../../assets/stylesheets/_helpers/_index_helpers';

.oDetails {
  @include baseCard;
  padding: 45px 35px;
  font-size: 17px;
  header {
    display: flex;
    font-size: 15px;
    h1 {
      font-size: 22px; }
    h2 {
      font-size: 17px; } }
  .oCard__unit {
    font-size: 15px; }
  dl {
    margin: 0;
    &:after {
      content: "";
      display: table;
      clear: both; } }
  dt, dd {
    float: left; }
  dt {
    clear: left;
    font-weight: 600;
    margin-right: 10px; }
  dd {
    margin: 0; }
  ul {
    @include ulReset; } }


.oDetails__image,
.oDetails__image--small {
  @include avatar(78px, 78px); }
.oDetails__image--small {
  width: 56px;
  height: 56px; }

.oDetails__delete-error {
  max-width: 200px;
  margin-top: 10px;
  font-size: 12px;
  color: $c-red;
  text-align: right; }

.oDetails__info {
  .tooltip__info {
    margin: -3px 10px 0 0; } }

.oDetails__delivery {
  font-size: 17px;
  > .ant-row {
    margin-right: 30px; }
  .icon-big {
    width: 25px;
    height: auto;
    margin-right: 10px; }
  .btn--yellow {
    margin-bottom: 20px;
    background: $c-yellow;
    color: $c-main; }
  .btn-orange {
    background: $c-orange; }
  .btn--red {
      background: $c-red;
      margin-right: 23px; }
  .btn--green {
      background: $c-green; }
  .icon-small {
    margin-right: 5px; } }

.oDetails__share {
  .ant-select {
    width: 100%; }
  .ant-btn {
    margin: 15px 0px; } }

.oDetails__donor {
  h3 {
    font-size: 17px;
    font-weight: 700; } }
.oDetails__donor-data {
  > div {
    width: 50%; } }

.oDetails__orderStatus {
  h3 {
    font-size: 18px;
    font-weight: 800;
    margin: 0; }
  .status--accepted {
    color: $c-green; }
  .status--rejected {
    color: $c-red !important; }
  .status--shared {
    color: $c-main; }
  .status--open {
    color: $c-orange; } }
