body {
  font-family: 'Barlow', sans-serif;
  font-size: 15px;
  color: $c-main; }
.highcharts-title {
  font-family: 'Barlow', sans-serif !important; }

.hl, h1, .h1 {
  color: $c-dark;
  font-weight: 700; }

h1, .h1 {
  font-size: 22px;
  line-height: 26px; }

.hl {
  font-size: 26px;
  line-height: 30px; }

.hxl {
  font-size: 40px;
  line-height: 53px;
  font-weight: 600;
  color: $c-main; }


a {
  color: $c-link; }

.f-s-12 {
  font-size: 12px !important; }
.f-s-15 {
  font-size: 15px !important; }
.f-s-16 {
  font-size: 16px !important; }
.f-s-17 {
  font-size: 17px !important; }
.f-s-18 {
  font-size: 18px !important; }
.f-s-20 {
  font-size: 20px !important; }
.f-s-22 {
  font-size: 22px !important; }
.f-s-26 {
  font-size: 26px !important; }

.f-w-500 {
  font-weight: 600 !important; }
.f-w-600 {
  font-weight: 600 !important; }
.is-strong {
  font-weight: 700 !important; }


.text-underline {
  text-decoration: underline; }
.text-right {
  text-align: right; }
.text-center {
  text-align: center; }

.text-uppercase {
  text-transform: uppercase !important; }


.c-green {
  color: $c-green; }
.c-red {
  color: $c-red; }
.c-orange {
  color: $c-orange; }
.c-white {
  color: $c-white; }
.c-light {
  color: $c-text-l; }

.required-sign {
  color: $c-main; }

.strike {
  text-decoration: line-through; }

@media (min-width: $bp-sm) {
  .hxl {
    font-size: 60px;
    line-height: 53px; } }
