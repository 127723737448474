@import '../../assets/stylesheets/_helpers/_index_helpers';

.modalDelete {
  @include baseCustomModal(350px);
  text-align: center;
  p {
    margin-bottom: 40px;
    font-size: 17px;
    font-weight: 600; }
  .ant-btn {
    margin: 0 20px; } }

@media (min-width: $bp-sm) {
  .modalDelete {
    p {
      padding: 0 30px; } } }
