.tabs {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
  li,
  .ant-radio-button-wrapper {
    margin-right: 5px;
    &:last-child {
      margin-right: 0; }
    a {
      display: flex;
      padding: 7px 15px;
      border-radius: 3px;
      text-transform: uppercase;
      background: #EFEFEF;
      color: $c-main; } }
  .ant-radio-button-wrapper {
    border: none !important;
    box-shadow: none !important;
    background: #EFEFEF;
    color: $c-main;
    &:not(:first-child)::before {
      display: none; } }


  .is-active a,
  li a:hover,
  .ant-radio-button-wrapper:hover,
  .ant-radio-button-wrapper-checked {
    background: $c-main-l;
    color: #fff; } }
