@import '../../assets/stylesheets/_helpers/_index_helpers';

.profileCard {
  img {
    width: 70px; }
  h1 {
    margin-bottom: 5px; }
  div {
    div {
      margin-bottom: 10px; } }
  .badge {
    margin-right: 15px; } }

.profileCard__avatar {
  border-radius: 50%;
  overflow: hidden;
  width: 70px;
  height: 70px;
  img {
    width: 70px;
    height: 70px;
    object-fit: cover; } }
