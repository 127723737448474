[class*='box--'],
.box {
  border-radius: 10px;
  padding: 25px; }
.box--white {
  background-color: $c-white;
  box-shadow: 0px 5px 5px 0px rgba(0,0,0,0.2); }
.box--grey-xl {
  background-color: $c-grey-xl; }
.box--grey-sm {
  background-color: $c-grey-xl;
  padding: 15px; }
.box--white-sm {
  background-color: $c-white;
  padding: 5px; }
