#launcher {
  right: auto !important;
  transform: scale(0.7) translateX(-30px) translateY(10px); }
#webWidget {
  right: auto !important; }

.Toastify__toast--error {
  background: #EA4867 !important; }
.Toastify__toast--success {
  background: #52B469 !important; }
.Toastify__toast-body {
  text-align: center; }


.ant-col {
  flex: 0 0 100%; }
.ant-spin-spinning {
  background: rgba(256,256,256,0.8);
  width: 70px;
  height: 70px;
  border-radius: 50%;
  border: 1px solid $c-main;
  box-shadow: 0 0 10px $c-dark;
  display: flex;
  justify-content: center;
  align-items: center; }

.ant-spin-dot-item {
  background: #F51A43; }

.ant-switch-checked {
  background-color: #B5DFC0;
  .ant-switch-handle::before {
    background-color: $c-green; } }

.ant-input-number {
  width: 100%; }

.ant-btn,
.ant-btn-primary {
  background: $c-main;
  border-color: transparent;
  border-radius: 5px;
  color: #fff;
  &:focus,
  &:active {
    background: $c-main;
    border-color: transparent;
    color: #fff; }
  &:hover {
    border-color: transparent;
    background-color: $c-main-l;
    color: #fff; } }
.ant-btn {
  &.btn--green {
    background-color: $c-green; }
  &.btn--grey-l {
    background-color: $c-main-l; }
  &.btn--grey-xl {
    background-color: #EFEFEF;
    color: $c-main; }
  &.btn--yellow {
    background-color: $c-yellow;
    color: $c-main; }
  &.btn--orange {
    background-color: $c-orange; }
  &.btn--red {
    background-color: $c-red; } }
.ant-btn {
  &.btn--transparent {
    background-color: transparent;
    color: $c-main;
    box-shadow: none; } }



.ant-form-item-label {
  > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    display: none; }
  > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
    display: inline-block;
    margin-right: 4px;
    color: $c-main;
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: '*'; } }


.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-radius: 5px; }
.ant-select-arrow {
  color: $c-main; }

textarea.ant-input {
  border-radius: 10px; }

.ant-form-item-explain-error {
  background: rgba(255,255,255,0.8); }


.select-for-mobile {
  select {
    display: none; } }

@media (max-width: $bp-sm) {
  .select-for-mobile {
    position: relative;
    select {
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      z-index: 10;
      width: 100%;
      opacity: 0; } } }
