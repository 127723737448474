@import '../../assets/stylesheets/_helpers/_index_helpers';

.dotContainer {
  width: 25px;
  flex-direction: column; }

.dot {
  background: $c-main;
  border-radius: 50px;
  width: 4px;
  height: 4px;
  margin-top: 2px; }

.menu {
  width: 110px; }
