* {
  box-sizing: border-box;
  outline: none !important; }

#root,
#root > .app-layout {
  min-height: 100%;
  height: 100%;
  display: block;
  padding: 0 !important;
  margin: 0 !important; }

button[type="button"]:not([class^="ant"]) {
  background: transparent;
  border: 0;
  cursor: pointer; }

.ant-layout {
  background: #fff; }

.container {
  max-width: 1140px;
  margin: 0 auto;
  padding: 0 30px; }
.container-small {
  max-width: 940px;
  margin: 0 auto; }



hr.div {
  border: 0;
  margin: 30px 0;
  display: block;
  height: 2px;
  background: url('../../images/div.png'); }


@media (max-width: $bp-m - 1) {
  #root > .app-layout main {
    padding-top: 90px !important; } }
