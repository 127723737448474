@mixin ulReset {
  margin: 0;
  padding: 0;
  list-style: none; }


@mixin arrows($color: '#000') {
  display: flex;
  justify-content: center;
  align-items: center;
  &:after {
    content: "";
    display: inline-block;
    width: 15px;
    height: 15px;
    background-repeat: no-repeat;
    border-bottom: 1px solid $color;
    border-left: 1px solid $color; } }

@mixin arrowRight {
  @include arrows; }

@mixin arrowleft {
  @include arrows; }


@mixin avatar($width: 56px, $height: 56px) {
  width: $width;
  height: $height;
  margin-right: 15px;
  border-radius: 50%;
  overflow: hidden;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover; } }

@mixin baseCard {
  width: 100%;
  background: #fff;
  box-shadow: 0px 3px 15px 0px #0000001A;
  border-radius: 8px;
  margin-bottom: 30px;
  padding: 15px 20px; }

@mixin baseCustomModal($maxWidth: 800px) {
  max-width: $maxWidth;
  margin: 0 auto;
  background: #fff;
  border-radius: 8px;
  padding: 50px 30px 30px; }

@mixin baseTag() {
  margin: 0 5px;
  padding: 2px 5px;
  border-radius: 5px;
  font-size: 10px;
  line-height: 17px;
  text-transform: uppercase;
  background: transparent; }
