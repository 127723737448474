@import '../../assets/stylesheets/_helpers/_index_helpers';

.registration-thank {
  margin: 0 auto;
  padding: 20px;
  text-align: center;
  p:last-child {
    margin: 0; } }


@media (min-width: $bp-sm) {
  .registration-thank {
    max-width: 320px;
    padding: 80px 0; } }
