@import '../../assets/stylesheets/_helpers/_index_helpers';

.filters {
  background: #EFEFEF;
  border-radius: 16px;
  padding: 20px; }

.filters__header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
  font-size: 12px;
  cursor: pointer;
  > * {
    display: flex;
    align-items: center; }
  > div:first-child {
    img {
      margin-right: 5px; } }
  > div:last-child {
    img {
      margin-left: 5px; } } }
.filters__more {
  overflow: hidden;
  transition: height 0.3s; }
// .filters__more--active
//   height: auto


.filters__sort {
  .ant-select {
    margin-left: 10px; }
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: 5px; } }

.accordion {
  img {
    transition: transform 0.3s; } }
.accordion--active {
  img {
    transform: rotate(180deg); } }

.filters__sort {
  .ant-select-single {
    width: 200px; } }

.filters__tag {
  margin-bottom: 15px; }

@media (max-width: $bp-sm - 1) {
  .filters__bottom {
    display: block;
    > div {
      width: 100%; }
    .ant-btn {
      display: inline-block;
      width: calc(50% - 18px);
      flex: 1; } }
  .filters__sort {
    & + .ant-btn {
      margin: 20px 8px 0 0;
      & + .ant-btn {
        margin: 20px 0 0 8px !important; } } } }


@media (min-width: $bp-sm) {
  .filters {
    .ant-col {
      padding-right: 3%; } }
  .filters__sort + .ant-btn {
    margin-left: 30px; } }


@media (min-width: $bp-m) {
  .filters {
    [type="submit"] {
      margin-top: -30px; } } }
