@import "vendor-rewrite";
@import "boxes";
@import "tabs";


.modalCustom {
  @include baseCustomModal(); }
.modal__close {
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 1000; }
