@import '../../assets/stylesheets/_helpers/_index_helpers';

.transactions {
  height: 500px; }
.ag-header-cell {
  background: $c-main-l;
  color: #fff; }
.ag-icon {
  color: #fff; }
.mT30 {
  margin-top: 30px; }
