@import '../../assets/stylesheets/_helpers/_index_helpers';

.offerNew {
  .ant-form-item-label {
    position: relative;
    > label {
      position: static;
      font-size: 17px;
      font-weight: 600; }
    > div {
      width: 100%; }
    img {
      position: absolute;
      right: 0; } }
  .ant-picker {
    width: 100%; } }

.offerNew__visibility {
  .ant-form-item-label {
    img {
      // position: relative
 } }      // right: 0
  .ant-radio-group {
    display: flex;
    flex-wrap: wrap; }
  .ant-radio-wrapper {
    display: flex;
    width: calc(33% - 20px);
    margin-right: 20px;
    white-space: normal; }
  .ant-radio {
    height: 16px;
    margin-top: 5px; }
  h3 {
    margin: 0;
    font-size: 15px;
    font-weight: 500; }
  p {
    color: $c-text-l; } }

.offerNew__type {
  .ant-radio-button-wrapper {
    border-radius: 30px;
    margin-right: 15px;
    box-shadow: none;
    border: 2px solid $c-grey-l;
    &:hover {
      color: $c-main; }
    &::before {
      display: none; }
    span:last-child {
      position: relative;
      top: -1px;
      font-size: 12px;
      text-transform: uppercase; } }
  .ant-radio-button-wrapper-checked {
    background: #E3C199 !important;
    border-color: #E3C199 !important;
    color: #fff;
    &:hover {
      color: #fff; } } }

.offerNew__hint {
  margin-top: -20px;
  text-align: right;
  font-size: 12pxs; }
