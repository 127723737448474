@import '../../assets/stylesheets/_helpers/_index_helpers';

.sCard {
  @include baseCard;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-width: 160px;
  border-radius: 16px;
  padding: 30px 15px;
  text-align: center;
  * {
    color: #fff; }
  header {
    width: 100%;
    margin-bottom: 20px; }
  img {
    height: 50px; }
  p {
    margin: 0; }
  h1 {
    font-size: 55px;
    font-weight: 800;
    span {
      margin-left: 5px;
      font-size: 18px; } } }




.sCard--blue {
  background: $c-light-blue; }
.sCard--green {
  background: $c-light-green; }
.sCard--yellow {
  background: $c-dark-yellow; }
.sCard--grey {
  background: #667079; }
.sCard--red {
   background: $c-red; }
