@import '../../../assets/stylesheets/_helpers/_index_helpers';

.layout-auth {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100%;
  background: $c-bg-grey;
  padding: 30px;

  .box--white {
    width: 100%; } }

@media (max-width: $bp-sm - 1) {
  .abs-centered {
    width: calc(100% - 40px); } }
@media (min-width: $bp-sm) {
  .layout-auth {
    .box--white {
      width: 570px; } } }
