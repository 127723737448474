@import '../../assets/stylesheets/_helpers/_index_helpers';

.view-messages {
  p {
    margin: 0;
    font-size: 18px; } }
.has-status-ok {
  color: $c-green; }
.has-status-ko {
  color: $c-red; }
