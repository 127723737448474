.hidden {
  display: none; }

@media (min-width: $bp-sm) {
  .visible-sm {
    display: block !important; }
  .row.visible-sm {
    display: flex !important; }
  table.visible-sm {
    display: table !important; }
  tr.visible-sm {
    display: table-row !important; }
  th.visible-sm,
  td.visible-sm {
    display: table-cell !important; }
  .flex-column-sm {
    flex-direction: column; } }

@media (min-width: $bp-m) {
  .visible-md {
    display: block !important; }
  .row.visible-md {
    display: flex !important; }
  table.visible-md {
    display: table !important; }
  tr.visible-md {
    display: table-row !important; }
  th.visible-md,
  td.visible-md {
    display: table-cell !important; }
  .flex-column-md {
    flex-direction: column; } }

@media (min-width: $bp-l) {
  .visible-lg {
    display: block !important; }
  .row.visible-lg {
    display: flex !important; }
  table.visible-lg {
    display: table !important; }
  tr.visible-lg {
    display: table-row !important; }
  th.visible-lg,
  td.visible-lg {
    display: table-cell !important; }
  .flex-column-lg {
    flex-direction: column; } }



@media (min-width: $bp-sm) {
  .hidden-md {
    display: none !important; } }
@media (min-width: $bp-m) {
  .hidden-lg {
    display: none !important; } }
@media (min-width: $bp-l) {
  .hidden-xl {
    display: none !important; } }
