@import '../../assets/stylesheets/_helpers/_index_helpers';

.organization__back {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  color: $c-main;
  &:hover {
    color: $c-main; }
  img {
    width: 12px;
    margin-right: 10px; } }

.requestPlan {
  border: 2px dashed $c-green;
  border-radius: 10px;
  padding: 20px; }

[class*='box-plan-'] {
  border-left: 5px solid;
  padding-left: 15px; }
.box-plan-free {
  border-color: $c-green; }
.box-plan-report,
.box-plan-opportunity {
  border-color: $c-yellow-d; }
.box-plan-advanced,
.box-plan-absolute {
  border-color: $c-blue; }
.box-plan-custom {
  border-color: $c-red; }
