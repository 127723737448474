@import '../../assets/stylesheets/_helpers/_index_helpers';


.organizations__head {
  margin-bottom: 30px;
  .ant-btn {
    height: 36px;
    background: $c-green;
    color: #fff; }
  .box {
    height: 36px;
    padding: 0 15px;
    display: flex;
    align-items: center; } }
.organizations__search {
  display: flex;
  align-items: center;
  .box {
    margin-right: 20px;
    background-image: url('../../assets/images/ico-search.svg');
    background-position: 10px 50%;
    background-repeat: no-repeat;
    padding-left: 40px; }
  input {
    width: 100%;
    border: 0;
    background: transparent; } }

.organizations {
  .box {
    margin-bottom: 30px; } }

.organizations__btns {
  display: flex;
  justify-content: space-between;
  align-items: center;
  > div {
    display: flex;
    align-items: center;

    button {
      margin-right: 10px; } } }
.organizations__card {
  > a,
  > button {
    display: flex;
    color: $c-main;
    text-align: left; }
  h1 {
    margin-bottom: 5px; }
  p {
    margin: 0;
    &:last-child {
      color: $c-text-l; } } }
.organizations__img {
  min-width: 40px;
  width: 40px;
  height: 40px;
  overflow: hidden;
  border-radius: 50%;
  margin-right: 15px;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover; } }
.organizations__id {
  > span {
    cursor: pointer; }
  margin-left: calc(6px + 40px + 15px);
  color: $c-text-l; }

.organizations__modal {
  .ant-modal-header {
    display: none; } }
@media (max-width: $bp-sm - 1) {
  .organizations__head {
    > .box {
      width: 100%;
      margin-top: 20px; } }
  .organizations__card {
    margin-bottom: 20px; }
  .organizations {
    .box {
      padding: 15px; } }
  .organizations__search {
    justify-content: space-between;
    .box {
      flex: 1; } } }

@media (min-width: $bp-sm) {
  .organizations__search {
    input {
      width: 300px; } }
  .organizations__img {
    min-width: 80px;
    width: 80px;
    height: 80px;
    margin-right: 30px; }
  .organizations__btns {
    justify-content: flex-end;
    > div {
      margin-right: 130px; } }
  .organizations__id {
    > span {
      cursor: pointer; }
    margin-left: calc(6px + 80px + 30px);
    color: $c-text-l; } }
