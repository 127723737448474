$c-blue: #226FB6;
$c-dark: #373737;
$c-green: #5AB26F;
$c-grey-l: #E5E5E5;
$c-grey-xl: #F8F8F8;
$c-main: #424E59;
$c-main-l: #667079;
$c-yellow: #FFDB00;
$c-yellow-d: #FEBA2C;
$c-orange: #FC9945;
$c-red: #EA4867;
$c-light-blue: #6EA4DB;
$c-light-green: #68C17D;
$c-dark-yellow: #EAD454;

$c-bg-grey: #414E59;
$c-bg-white: #FFFFFF;

$c-link: #1C84E3;
$c-text-l:  #9A9A9A;

$c-none: trasparent;
$c-white: #ffff;

$bp-sm: 768px;
$bp-m: 992px;
$bp-l: 1200px;


$img: '../images/';

