.with-letters {
  position: relative;
  [type='text'] {
    padding-right: 40px; }
  .ant-form-item-control-input-content {
    &:after {
      content: "";
      display: inline-block;
      position: absolute;
      right: 6px;
      top: 3px; } } }
.with-letters__€ {
  .ant-form-item-control-input-content {
    &:after {
      content: "€"; } } }
.with-letters__cm {
  .ant-form-item-control-input-content {
    &:after {
      content: "cm"; } } }
.with-letters__Kg {
  .ant-form-item-control-input-content {
    &:after {
      content: "Kg"; } } }
