@import '../../assets/stylesheets/_helpers/_index_helpers';

.offers {
  > div {
    display: flex;
    cursor: pointer; } }

@media (min-width: $bp-sm) {
  .offers {
    > div {
      // margin: 0 6px
      width: calc(50% - 10px); } } }

@media (min-width: $bp-m) {
  .offers {
    > div {
      width: calc(33% - 10px); } } }


.offer-modal {
  width: auto !important;
  max-width: 815px;
  .ant-modal-header,
  .ant-modal-footer {
    display: none; }
  .ant-modal-body {
    padding: 0; }
  .ant-modal-content {
    position: relative;
    background: transparent; } }

