@import '../../assets/stylesheets/_helpers/_index_helpers';

.logo {
  display: block;
  height: 65px;
  width: 251px;
  background: url('../../assets/images/logo-regusto.png');
  background-size: cover;
  text-indent: -9999px;
  overflow: hidden; }

.logo--small {
  width: 43px;
  height: 43px;
  background-size: 165px auto; }

@media (min-width: $bp-sm) {
  .logo--small {
    width: 165px; }

  .l-header .logo {
    width: 43px;
    background-size: 165px auto; } }
@media (min-width: $bp-l) {
  .l-header .logo {
    width: 165px;
    background-size: cover; } }
